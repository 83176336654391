import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  Input,
  Label,
  LoadingText,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';
import { SelectOption, ReasonsContentfulEntry, AppointmentDetail } from 'types';
import { getSelectedTime, getSelectedTimeDayJs, timeOptions as appointmentTimeOptions } from 'utils/time-options';

import { useClientAttendanceRescheduleAppointment } from 'hooks/use-appointments';
import { faCalendarEdit } from '@fortawesome/pro-solid-svg-icons';

import TimezoneSelect from 'components/timezone-select';
import { Controller, useForm, useWatch } from 'react-hook-form';
import RHFSelect from 'components/RHFSelectBase';
import RHFSingleDatePicker from 'components/RHFSingleDatePicker';
import FormGroupTitle from 'components/form-group-title';
import { useContentfulEntry } from 'hooks/use-ContentfulData';
import { mapEntryToSelectOption } from './log-cancelation-modal';

import { displayTimezoneOptions } from 'components/appointment-modals';
import ErrorSpan from 'components/error-span';
import 'twin.macro';
import ModalHeadline from 'components/modal-headline';
import { clientFullName } from 'utils/appointments-utils';
import { useCallDataOnTruthy } from 'hooks/use-call-data-on-truthy';
import { useTherapistAvailableTimesV2 } from 'components/therapistMatcher/data';
import useTherapists, { useTherapist } from 'hooks/use-therapists';
import { RescheduleDetailFormPayload, RescheduleDetailDTO } from 'domain/log-attedances/types';
import { AttendanceErrorKey, RESCHEDULE_DETAIL_INITIAL_STATE } from 'domain/log-attedances/constants';
import { appointmentLengthOptions, initialEvaluationLengthOptions } from 'domain/appointment/constants';
import { EntryId } from 'hooks/use-contentful';
import { useCreatePendingReschedule } from 'hooks/use-pending-reschedule';
import { PendingRescheduleDTO } from 'domain/pending-reschedule/types';
import { isOtherReasonValid } from 'utils/helpers';
import { useClient } from 'hooks/use-client';
import { uniqBy } from 'lodash';
import { getCueError } from 'utils/error';
import { shouldSkipAttendanceError } from 'hooks/shared/attendance-utils';
import { IConflict } from 'utils/appointments/conflicts';
import Conflicts from 'components/appointment-modals/conflicts';
import { getWeekSummaryStatusDescription } from 'domain/therapist/constants';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface LogRescheduleModalProps {
  isOpen: boolean;
  activity: AppointmentDetail;
  contactTimeZone?: string;
  onClose: () => void;
}

/**
 * @desc converts a date string and a selected time from dropdown to a Moment date object
 * @param date<String> - date to be formatted
 * @param time<SelectOption> - time to be formatted from select
 * @returns Momennt instance of the date
 */
export const joinDateAndTimeFromOptions = (date: string, time: SelectOption, timezone: SelectOption) => {
  const appointmentDateTimeMoment = moment(date).tz(timezone.value);
  appointmentDateTimeMoment?.set({ hours: 0, minute: 0, second: 0, millisecond: 0 });
  appointmentDateTimeMoment?.add(time?.value, 'hours');

  return appointmentDateTimeMoment;
};

export const mapRescheduleDetailFormPayloadToRescheduleDTO = (
  data: RescheduleDetailFormPayload,
): RescheduleDetailDTO => {
  const { status, rescheduleDetail } = data;
  // convert the date based on selected timezone
  const appointmentDateTimeMoment = joinDateAndTimeFromOptions(
    rescheduleDetail.appointmentDateTime,
    rescheduleDetail.time,
    rescheduleDetail.userTimezone,
  );

  return {
    status,
    therapistEmail: data.therapistEmail,
    rescheduleDetail: {
      userTimezone: rescheduleDetail.userTimezone.value,
      appointmentDateTime: appointmentDateTimeMoment.tz('Africa/Abidjan').format('YYYY-MM-DDTHH:mm:ss-00:00'), //timezone reset based on user selected timezone
      duration: Number(rescheduleDetail.duration.value),
      rescheduledBy: rescheduleDetail.rescheduledBy,
      rescheduledReason: rescheduleDetail.rescheduledReason,
      rescheduleReasonOther: rescheduleDetail.rescheduleReasonOther,
    },
    override: !data.availableTimes,
  };
};

export const findTimezoneOptionsByUtcOffset = (timezone: SelectOption) => {
  const date = moment();
  const timezoneArrayOffset = moment.tz(date, timezone.value).utcOffset();
  const appointmentTimeOffset = date?.utcOffset();
  return appointmentTimeOffset === timezoneArrayOffset;
};

const DEFAULT_SKIPPABLE_ERRORS = [AttendanceErrorKey.ExistingPendingReschedule, AttendanceErrorKey.AlreadyLogged];

export default function LogRescheduleModal(props: LogRescheduleModalProps) {
  const [availableTimes, setAvailableTimes] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<RescheduleDetailFormPayload>({
    defaultValues: RESCHEDULE_DETAIL_INITIAL_STATE,
  });
  const watchReason = watch('rescheduleDetail.rescheduledReason');
  const watchDate = useWatch({ control, name: 'rescheduleDetail.appointmentDateTime' });
  const watchTime = useWatch({ control, name: 'rescheduleDetail.time' });
  const watchTimezone = watch('rescheduleDetail.userTimezone');
  const watchDuration = useWatch({ control, name: 'rescheduleDetail.duration.value' });
  const watchRescheduleReasonOther = useWatch({ control, name: 'rescheduleDetail.rescheduleReasonOther' });
  const { isOpen, onClose, activity: appointmentData } = props;
  const { data: therapistData } = useTherapist(appointmentData.therapistEmail);

  const [cancelationReasonsEntry, , fetchContentfulData] = useContentfulEntry<ReasonsContentfulEntry>(
    EntryId.RescheduleReasons,
  );
  const cancelationReasons = cancelationReasonsEntry?.fields?.dropdownContent.map(mapEntryToSelectOption);

  const [rescheduleType, setRescheduleType] = useState<'link' | 'self-reschedule' | ''>('');

  const editAppointmentMutation = useClientAttendanceRescheduleAppointment();
  const createPendingRescheduleMutation = useCreatePendingReschedule();
  const { mutateAsync: createPendingReschedule } = createPendingRescheduleMutation;
  const { mutateAsync: rescheduleAppointmentMutation } = editAppointmentMutation;
  const { data: clientData } = useClient(appointmentData.clientID);

  const { data: therapists } = useTherapists('active');
  const [selectedTherapist, setSelectedTherapist] = useState<SelectOption>({
    label: clientData?.careTeam?.primary?.therapistName ?? '',
    value: clientData?.careTeam?.primary?.therapistEmail ?? '',
  });

  const isNotEvaluation = appointmentData?.appointmentTypeID !== 'evaluation';
  const primaryContact = clientData?.contactInformation?.find(contact => contact.primaryContact);
  const contactState = primaryContact?.address?.state;

  const [conflicts, setConflicts] = useState<IConflict[]>([]);

  useCallDataOnTruthy(isOpen, fetchContentfulData);

  const { data: availableTimesV2, isLoading } = useTherapistAvailableTimesV2({
    calendarID: therapistData?.acuityCalendarID,
    date: dayjs(watchDate)?.format('YYYY-MM-DD'),
    duration: Number(watchDuration),
    type: isNotEvaluation ? 'session' : 'evaluation',
  });

  const availableTimesOptions = availableTimesV2?.items?.map(availableTime => ({
    label: dayjs(availableTime.time).format('h:mm A'),
    value: getSelectedTimeDayJs(dayjs(availableTime.time)).value,
  }));

  const handleOnChangeRescheduleType = (value: 'link' | 'self-reschedule') => {
    setRescheduleType(value);
  };

  useEffect(() => {
    if (availableTimesOptions?.length && availableTimes) {
      setValue('rescheduleDetail.time', availableTimesOptions[0] as any);
    } else {
      setValue(
        'rescheduleDetail.time',
        getSelectedTime(moment(appointmentData?.appointmentDateTime)) as unknown as SelectOption,
      );
    }
  }, [availableTimesOptions?.length, availableTimes]);

  useEffect(() => {
    setAvailableTimes(true);
    const timezoneValue = displayTimezoneOptions.find(findTimezoneOptionsByUtcOffset) ?? displayTimezoneOptions[1];
    const appointmentDurationValue = isNotEvaluation
      ? (appointmentLengthOptions.find(
          duration => Number(duration.value) === Number(appointmentData?.appointmentDuration),
        ) as SelectOption)
      : appointmentLengthOptions[1];

    const initialEvaluationDurationValue = !isNotEvaluation
      ? (initialEvaluationLengthOptions.find(
          duration => Number(duration.value) === Number(appointmentData?.appointmentDuration),
        ) as SelectOption)
      : initialEvaluationLengthOptions[0];

    const durationValue = isNotEvaluation ? appointmentDurationValue : initialEvaluationDurationValue;

    // populate the form based on appointment data
    setValue('rescheduleDetail.rescheduleReasonOther', '');
    setValue('rescheduleDetail.rescheduledReason', '');
    setValue('rescheduleDetail.appointmentDateTime', appointmentData?.appointmentDateTime ?? '');
    setValue('rescheduleDetail.userTimezone', timezoneValue as unknown as SelectOption);
    setValue('rescheduleDetail.duration', durationValue as unknown as SelectOption);
    setRescheduleType('');

    // set value dont trigger a re-render on effect/render phase
    // we must reset form manually based on new values
    reset({ ...getValues() });
    setErrorMessage(undefined);
  }, [isOpen]);

  useEffect(() => {
    setSelectedTherapist({
      label: clientData?.careTeam?.primary?.therapistName ?? '',
      value: clientData?.careTeam?.primary?.therapistEmail ?? '',
    });
  }, [isOpen, clientData]);

  useEffect(() => {
    if (isLoading || !watchDate || !watchTime || !watchTimezone) {
      setConflicts([]);
      return;
    }

    const appointmentDateTimeMoment = joinDateAndTimeFromOptions(watchDate, watchTime, watchTimezone);

    const selectedDateFormatted = appointmentDateTimeMoment?.toISOString();

    const isDateInAvailableTimes = (item: any) => {
      const itemTime = moment(item.time).tz(watchTimezone.value).toISOString();
      return itemTime === selectedDateFormatted;
    };

    const handleOverride = () => {
      const isAvailable = availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = selectedDateFormatted;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: selectedDateFormatted,
          type: availableTimes ? 'appointmentExists' : 'futureConflict',
        });
      }

      setConflicts(Array.from(conflictMap.values()));
    };

    const handleNonOverride = () => {
      const isAvailable =
        availableTimesV2?.items?.length === 0 || availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = selectedDateFormatted;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: selectedDateFormatted,
          type: availableTimes ? 'appointmentExists' : 'futureConflict',
        });
      }

      const selectedItem = availableTimesV2?.items?.find(isDateInAvailableTimes);
      selectedItem?.conflicts?.forEach((conflict: { datetime: string; type: string }) => {
        conflictMap.set(conflict?.datetime, {
          datetime: conflict?.datetime,
          type: availableTimes ? conflict?.type : 'futureConflict',
        });
      });

      setConflicts(Array.from(conflictMap.values()));
    };

    if (!availableTimes) handleOverride();
    else handleNonOverride();
  }, [isLoading, availableTimes, availableTimesV2, watchDate, watchTime, watchTimezone.value, setConflicts]);

  const onSubmitReschedule = async (data: RescheduleDetailFormPayload) => {
    if (rescheduleType === 'link') {
      const dto: PendingRescheduleDTO = {
        acuityAppointmentID: appointmentData.acuityAppointmentID,
        clientID: appointmentData.clientID,
        rescheduledBy: data.rescheduleDetail.rescheduledBy,
        rescheduleReason: data.rescheduleDetail.rescheduledReason,
        rescheduleSourceType: 'rescheduling',
      };

      if (data.rescheduleDetail.rescheduledReason.toLocaleLowerCase() === 'other') {
        dto.rescheduleReasonOther = data.rescheduleDetail.rescheduleReasonOther;
      }

      await createPendingReschedule(dto, {
        onError: err => {
          if (shouldSkipAttendanceError(err, DEFAULT_SKIPPABLE_ERRORS)) {
            onClose();
            return;
          }
          const error = getCueError(err);
          setErrorMessage(error.message);
          return;
        },
      });

      onClose();
      return;
    }

    const rescheduleDTO = mapRescheduleDetailFormPayloadToRescheduleDTO({
      ...data,
      therapistEmail: selectedTherapist.value,
      availableTimes,
    });
    await rescheduleAppointmentMutation(
      {
        clientID: appointmentData.clientID,
        acuityId: appointmentData.acuityAppointmentID ?? '',
        data: rescheduleDTO,
      },
      {
        onError: err => {
          if (shouldSkipAttendanceError(err, DEFAULT_SKIPPABLE_ERRORS)) {
            onClose();
            return;
          }
          const error = getCueError(err);
          if ('code' in error && (error as any)?.code === 'THERAPIST_OVER_RATE_LIMIT') {
            setErrorMessage('Therapist is over direct hour rate limit for the week.');
            return;
          }
          if ('code' in error && (error as any)?.code === 'CONFLICTING_APPOINTMENT') {
            setErrorMessage('The selected time is no longer available. Please choose a different time.');
            return;
          }
          setErrorMessage(error?.message);
          return;
        },
      },
    );

    onClose();
  };

  const therapistSelectOptions: SelectOption[] | undefined = useMemo(() => {
    const primaryContact = clientData?.contactInformation?.find(contact => contact.primaryContact);
    const contactState = primaryContact?.address?.state;

    if (!contactState)
      return therapists?.map(therapist => ({ label: therapist?.therapistName, value: therapist?.therapistEmail }));

    const therapistByState = therapists?.filter(therapist => therapist.statesLicensed.includes(contactState));
    if (therapistByState?.length) {
      return therapistByState?.map(therapist => ({
        label: `${therapist.firstName} ${therapist.lastName}`,
        value: therapist?.therapistEmail,
      }));
    }

    return [];
  }, [contactState, therapists]);

  useEffect(() => {
    if (!isNotEvaluation && isOpen) {
      setRescheduleType('self-reschedule');
    }
  }, [isNotEvaluation, isOpen]);

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <form data-testid="log-reschedule-form" onSubmit={handleSubmit(onSubmitReschedule)}>
          <ModalHeader>
            <div tw="absolute top-0 right-0 pt-4 pr-4">
              <CloseButton testId="close-log-cancelation-modal" onClick={onClose} />
            </div>
          </ModalHeader>
          <ModalBody>
            <div tw="my-1">
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faCalendarEdit} />
              </div>
              <div tw="mt-3 sm:mt-5">
                <ModalHeadline text={`Log Reschedule for ${clientFullName(appointmentData)}`} />
                <h4 tw="text-center leading-6 -mt-3">
                  {dayjs(appointmentData?.appointmentDateTime).tz(dayjs.tz.guess()).format('MM/DD/YYYY')} at&nbsp;
                  {dayjs(appointmentData?.appointmentDateTime).tz(dayjs.tz.guess()).format('hh:mm A')}
                </h4>
                <TimezoneSelect
                  displayTimezone={watchTimezone as unknown as Record<string, string>}
                  setDisplayTimezone={setValue}
                  name="rescheduleDetail.userTimezone"
                  disabled={true}
                />
                <div tw="mt-8">
                  <FormGroupTitle title="Reschedule initiated by" fontSize="small" fontWeight="semi" spacing="large" />
                  <FormGroup type="inline" tw="items-center">
                    <Input
                      type="radio"
                      id="rescheduleDetail.rescheduleBy-client"
                      spacing="tight"
                      data-testid="reschedule-by-client"
                      {...register('rescheduleDetail.rescheduledBy', { required: 'Please fill out this field.' })}
                      value="client"
                    />
                    <Label font="normal" htmlFor="rescheduleDetail.rescheduleBy-client" tw="ml-2">
                      Client
                    </Label>

                    <Input
                      id="rescheduleDetail.rescheduleBy-therapist"
                      tw="ml-4"
                      spacing="tight"
                      type="radio"
                      data-testid="reschedule-by-therapist"
                      {...register('rescheduleDetail.rescheduledBy', { required: 'Please fill out this field.' })}
                      value="therapist"
                    />
                    <Label font="normal" htmlFor="rescheduleDetail.rescheduleBy-therapist" tw="ml-2">
                      Therapist
                    </Label>
                  </FormGroup>
                  <ErrorSpan error={errors?.rescheduleDetail?.rescheduledBy?.message} />
                </div>
                <FormGroup tw="mt-6" data-testid="reschedule-reason">
                  <FormGroupTitle title="Reschedule Reason" fontSize="small" fontWeight="semi" spacing="large" />
                  <RHFSelect
                    name="rescheduleDetail.rescheduledReason"
                    control={control}
                    required
                    options={cancelationReasons}
                    error={errors?.rescheduleDetail?.rescheduledReason}
                  />
                </FormGroup>
                {Boolean(watchReason === 'other') && (
                  <FormGroup>
                    <FormGroupTitle title="What was the reason?" fontSize="small" fontWeight="semi" spacing="normal" />
                    <Input
                      tw="w-full"
                      data-testid="reschedule-reason-other"
                      {...register('rescheduleDetail.rescheduleReasonOther', { required: true })}
                      error={errors.rescheduleDetail?.rescheduleReasonOther}
                    />
                  </FormGroup>
                )}

                {isNotEvaluation && (
                  <div tw="mt-2 mb-8">
                    <FormGroupTitle title="Select one" fontSize="small" fontWeight="semi" spacing="large" />
                    <FormGroup type="inline">
                      <Input
                        type="radio"
                        id="reschedule-link"
                        name="scheduleType"
                        spacing="tight"
                        data-testid="reschedule-link"
                        value="link"
                        required
                        onChange={e => handleOnChangeRescheduleType(e.target.value as 'link' | 'self-reschedule')}
                      />
                      <Label font="normal" htmlFor="reschedule-link" tw="ml-2">
                        Send your client a reschedule link
                      </Label>
                    </FormGroup>

                    <FormGroup type="inline" tw="mt-2">
                      <Input
                        id="self-reschedule"
                        name="scheduleType"
                        spacing="tight"
                        type="radio"
                        data-testid="self-reschedule"
                        value="self-reschedule"
                        required
                        onChange={e => handleOnChangeRescheduleType(e.target.value as 'link' | 'self-reschedule')}
                      />
                      <Label font="normal" htmlFor="self-reschedule" tw="ml-2">
                        Reschedule for your client
                      </Label>
                    </FormGroup>
                  </div>
                )}

                {rescheduleType == 'self-reschedule' && (
                  <>
                    <FormGroup tw="mb-3">
                      <FormGroupTitle title="Therapist" fontSize="small" fontWeight="semi" spacing="normal" />
                      <Select
                        aria-label="appointment-therapist-dropdown"
                        name="appointment-therapist-dropdown"
                        data-testid="add-appointment-therapist"
                        id="appointment-therapist-dropdown"
                        value={selectedTherapist}
                        disabled={!contactState}
                        options={uniqBy(
                          [
                            ...(therapistSelectOptions ?? []),
                            {
                              label: `${therapistData?.firstName} ${therapistData?.lastName}`,
                              value: therapistData?.therapistID,
                            },
                          ],
                          'value',
                        )}
                        onChange={(e: SelectOption) => setSelectedTherapist(e)}
                        tw="mb-2"
                      />
                      <div className="text-xs text-gray-500 mb-3">
                        {contactState
                          ? `Only therapists with a state licensure of ${contactState} are displayed`
                          : 'Please add a state to the primary contact to be able to add to another therapist'}
                      </div>
                    </FormGroup>
                    <div tw="mt-3 flex">
                      <Input
                        data-testid="edit-appointment-available-times"
                        name="available-times"
                        id="available-times"
                        type="checkbox"
                        tw="mr-2"
                        checked={availableTimes}
                        onChange={e => setAvailableTimes(e.currentTarget.checked)}
                        disabled={isLoading}
                      />
                      <Label font="normal" htmlFor="available-times">
                        Only Show Available Times
                      </Label>
                    </div>
                    {!availableTimes && (
                      <div tw="bg-[#FEE2E2] p-3 rounded-md mb-4">
                        Please note that viewing unavailable times removes all calendar protections and may result in a
                        schedule conflict.
                      </div>
                    )}
                    <div tw="flex mt-3">
                      <div tw="w-1/2 mr-2">
                        <div data-testid="edit-appointment-date">
                          <Label tw="mb-2">New Date</Label>
                          <RHFSingleDatePicker
                            control={control}
                            name="rescheduleDetail.appointmentDateTime"
                            currentDate={moment(appointmentData?.appointmentDateTime)}
                            required={true}
                          />
                        </div>
                      </div>
                      {availableTimes ? (
                        <div tw="w-1/2 ml-2">
                          {isLoading ? (
                            <div data-testid="edit-appointments-loading" tw="flex-1 text-center mt-8">
                              <LoadingText />
                            </div>
                          ) : (
                            <div>
                              {!availableTimesV2?.isRateLimited && availableTimesV2?.items?.length ? (
                                <>
                                  <Label tw="mb-2">New Time</Label>
                                  <div data-testid="edit-appointments-available-time">
                                    <Controller
                                      name="rescheduleDetail.time"
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          id="reschedule.time"
                                          options={availableTimesOptions}
                                          isSearchable
                                          placeholder="–"
                                        />
                                      )}
                                      defaultValue={getValues().rescheduleDetail.time}
                                      rules={{ required: true }}
                                    />
                                  </div>
                                </>
                              ) : (
                                <div
                                  tw="flex items-center justify-center text-gray-500 mt-6 ml-4"
                                  data-testid="not-available-times"
                                >
                                  {availableTimesV2?.isRateLimited ? (
                                    getWeekSummaryStatusDescription(availableTimesV2.rateLimitStatus)
                                  ) : (
                                    <>
                                      <span className="mt-3">No times available today</span>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div tw="w-1/2 ml-2">
                          <div data-testid="reschedule-appointment-time">
                            <Label tw="mb-2">New Time</Label>
                            <Controller
                              name="rescheduleDetail.time"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  id="reschedule.time"
                                  options={appointmentTimeOptions}
                                  isSearchable
                                  placeholder="–"
                                />
                              )}
                              defaultValue={getValues().rescheduleDetail.time}
                              rules={{ required: true }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div tw="flex mt-1">
                      <div tw="w-full">
                        <div data-testid="edit-appointment-duration">
                          {/* Reschedule Speech Therapy Session Duration */}
                          {!Boolean(appointmentData?.appointmentTypeID === 'evaluation') && (
                            <React.Fragment>
                              <Label tw="mb-2">Duration</Label>
                              <Controller
                                name="rescheduleDetail.duration"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    id="reschedule.duration"
                                    options={appointmentLengthOptions}
                                    isSearchable
                                    placeholder="–"
                                  />
                                )}
                                defaultValue={getValues().rescheduleDetail.duration}
                                rules={{ required: true }}
                              />
                            </React.Fragment>
                          )}
                          {/* Reschedule Initial Evaluation Duration */}
                          {Boolean(appointmentData?.appointmentTypeID === 'evaluation') && (
                            <React.Fragment>
                              <Label tw="mb-2">Duration</Label>
                              <Controller
                                name="rescheduleDetail.duration"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    id="reschedule.duration"
                                    options={initialEvaluationLengthOptions}
                                    isSearchable
                                    placeholder="–"
                                  />
                                )}
                                defaultValue={getValues().rescheduleDetail.duration}
                                rules={{ required: true }}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {!isLoading && conflicts.length > 0 && (
                      <div className="mb-5">
                        <Conflicts
                          conflicts={conflicts}
                          availableTimes={availableTimes}
                          displayTimezone={watchTimezone.value}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {rescheduleType === 'link' && (
              <p className="mb-8">
                Your client will receive a reschedule link via SMS and select a new appointment date and time based on
                your availability.
              </p>
            )}

            {errorMessage && <p tw="text-center text-red-600 font-semibold mb-2">{errorMessage}</p>}
            <div tw="mt-3 mb-3">
              <span tw="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button
                  data-testid="edit-appointment-submit"
                  type="submit"
                  variant="primary"
                  loading={editAppointmentMutation.isLoading || createPendingRescheduleMutation.isLoading}
                  disabled={
                    rescheduleType === 'link'
                      ? Boolean(!rescheduleType || !watchReason || !watchReason.length) ||
                        isOtherReasonValid(watchReason, watchRescheduleReasonOther)
                      : editAppointmentMutation.isLoading ||
                        (availableTimes && availableTimesV2?.isRateLimited) ||
                        (availableTimes && !availableTimesOptions?.length) ||
                        !watchReason ||
                        !rescheduleType ||
                        isOtherReasonValid(watchReason, watchRescheduleReasonOther)
                  }
                  fullWidth
                >
                  {rescheduleType === 'link' ? 'Send Reschedule Link' : 'Reschedule Appointment'}
                </Button>
              </span>
            </div>
            <div tw="mt-3 mb-3">
              <span tw="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button data-testid="edit-appointment-submit" onClick={() => onClose()} variant="secondary" fullWidth>
                  Exit Without Logging
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
