import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Link,
  Menu,
  MenuItem,
  MenuLink,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormGroupTitle from 'components/form-group-title';
import { ReasonsContentfulEntry, RescheduledBy, SelectOption } from 'types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { uniqBy } from 'lodash';
import useTherapists, { useTherapist } from 'hooks/use-therapists';
import { getSelectedTimeDayJs, timeOptions } from 'utils/time-options';
import { APPOINTMENT_TIME_10_AM, displayTimezoneOptions } from './add-appointments';
import moment, { Moment } from 'moment';
import { mapEntryToSelectOption } from 'components/client-attendance-modals/log-cancelation-modal';
import { useTherapistAvailableTimesV2 } from 'components/therapistMatcher/data';
import { getDateFromDayOfWeekAndTime } from 'utils/helpers';
import {
  useReassignRecurringAppointment,
  useRescheduleRecurringAppointment,
  useUpcomingRecurringAppointments,
} from 'hooks/use-appointments';
import { EntryId, useContentfulEntry } from 'hooks/use-contentful';
import { useQueryClient } from 'react-query';
import { getCueError } from 'utils/error';
import {
  DAY_SELECT_OPTIONS,
  RecurringAppointmentsActionType,
  RecurringAppointmentsKeywordErrors,
} from 'domain/recurring-appointments/constants';
import { EditRecurringAppointmentsModalProps } from 'domain/recurring-appointments/types';
import 'twin.macro';
import Conflicts from './conflicts';
import { IConflict } from 'utils/appointments/conflicts';

dayjs.extend(utc);
dayjs.extend(timezone);

export function EditRecurringAppointmentsModal(props: EditRecurringAppointmentsModalProps) {
  const { isOpen, onClose, editRecurringAppointmentData, contactState, openCancelModal, isCancelAllowed } = props;
  const [errorMessage, setErrorMessage] = useState<string>();
  const queryClient = useQueryClient();
  const [actionType, setActionType] = useState('reschedule');
  const [time, setTime] = useState<{ label: string; value: number; nonTzValue: number } | null>(null);
  const [displayTimezone, setDisplayTimezone] = useState<Record<string, string>>(displayTimezoneOptions[0]);
  const [isAvailableTimes, setIsAvailableTimes] = useState<boolean>(true);
  const [date, setDate] = useState<Moment | null>(moment());
  const [isGuessTimezoneReady, setIsGuessTimezoneReady] = useState<boolean>(false);
  const [rescheduledBy, setRescheduledBy] = useState<RescheduledBy | ''>('');
  const [rescheduledReason, setRescheduledReason] = useState<string>('');
  const [rescheduledReasonOther, setRescheduledReasonOther] = useState<string | undefined>();
  const [conflicts, setConflicts] = useState<IConflict[]>([]);

  const { data: cancelationReasonsEntry } = useContentfulEntry<ReasonsContentfulEntry>({
    entryId: EntryId.RescheduleReasons,
    unwrapArray: true,
  });
  const cancelationReasons = cancelationReasonsEntry?.dropdownContent.map(mapEntryToSelectOption);
  const { data: upcomingRecurringAppointments, isLoading: upcomingRecurringAppointmentsLoading } =
    useUpcomingRecurringAppointments(
      editRecurringAppointmentData.clientID,
      editRecurringAppointmentData.recurringAppointmentID,
    );
  const { data: therapistData } = useTherapist(editRecurringAppointmentData.therapistID);
  const { data: therapists } = useTherapists('active');
  const { mutateAsync: reassignRecurringAppointment, isLoading: isReassignRecurringAppointmentLoading } =
    useReassignRecurringAppointment();
  const { mutateAsync: rescheduleRecurringAppointment, isLoading: isRescheduleRecurringAppointmentLoading } =
    useRescheduleRecurringAppointment();

  const recurringAppointmentDefaultDay = DAY_SELECT_OPTIONS.find(
    dayOption =>
      dayOption.label.toLowerCase() ===
      dayjs(editRecurringAppointmentData?.startDate).tz(dayjs.tz.guess()).format('dddd').toLowerCase() + 's',
  );

  const [day, setDay] = useState<SelectOption>(recurringAppointmentDefaultDay as SelectOption);

  const [selectedTherapist, setSelectedTherapist] = useState<SelectOption>({
    label: `${editRecurringAppointmentData?.therapistFirstName} ${editRecurringAppointmentData?.therapistLastName}`,
    value: editRecurringAppointmentData?.therapistID,
  });

  const getDateInSameWeek = (startDate: string, dayOfWeek: number): string => {
    const start = moment.utc(startDate);
    const now = moment.utc();
    const currentDay = start.isBefore(now, 'week') ? now.day() : start.day();
    const diff = dayOfWeek - currentDay;
    const result = start.isBefore(now, 'week') ? now.add(diff, 'days') : start.add(diff, 'days');
    return result.format('YYYY-MM-DD');
  };

  const {
    data: availableTimesV2,
    isLoading: isAvailableTimesLoading,
    isError: isAvailableTimesLoadingError,
  } = useTherapistAvailableTimesV2({
    calendarID: therapistData?.acuityCalendarID,
    date: getDateInSameWeek(editRecurringAppointmentData?.startDate, Number(day.value)),
    type: 'session',
    reccuringNo: 10,
    duration: editRecurringAppointmentData?.duration || 30,
  });

  const isLoadingState =
    isAvailableTimesLoading || isReassignRecurringAppointmentLoading || isRescheduleRecurringAppointmentLoading;

  const availableTimesOptions = useMemo(() => {
    if (isLoadingState) return;
    return availableTimesV2?.items?.map(availableTime => {
      const label = dayjs(availableTime.time).tz(displayTimezone.value).format('h:mm A');
      const value = getSelectedTimeDayJs(dayjs(availableTime.time).tz(displayTimezone.value)).value;
      const nonTzValue = getSelectedTimeDayJs(dayjs(availableTime.time)).value;

      return {
        label,
        value,
        nonTzValue,
      };
    });
  }, [isLoadingState, displayTimezone.value, isAvailableTimes, day.value, availableTimesV2]);

  const therapistSelectOptions: SelectOption[] | undefined = useMemo(() => {
    if (!contactState)
      return therapists?.map(therapist => ({ label: therapist.therapistName, value: therapist.therapistEmail }));
    return therapists
      ?.filter(therapist => therapist.statesLicensed.includes(contactState))
      .map(therapist => ({ label: therapist.therapistName, value: therapist.therapistEmail }));
  }, [contactState, therapists]);

  const therapist = useMemo(() => {
    const currentTherapist = therapists?.find(therapist => therapist.therapistEmail === selectedTherapist.value);
    if (currentTherapist) return currentTherapist;
    return therapistData;
  }, [therapists, selectedTherapist, therapistData]);

  const handleRadioButtonActionType = (event: { target: { value: React.SetStateAction<string> } }) => {
    setActionType(event.target.value);
  };

  const handleOnChangeRescheduledBy = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRescheduledBy(e.target.value as RescheduledBy);
  }, []);

  useEffect(() => {
    setDisplayTimezone(() => {
      const guessTimezone =
        displayTimezoneOptions.find(timezone => {
          const timezoneArrayOffset = moment.tz(date, timezone.value).utcOffset();
          const appointmentTimeOffset = date?.utcOffset();
          return appointmentTimeOffset === timezoneArrayOffset;
        }) ?? displayTimezoneOptions[1];
      if (guessTimezone) {
        setIsGuessTimezoneReady(true);
      }
      return guessTimezone;
    });
  }, []);

  useEffect(() => {
    const newTime = availableTimesOptions?.find(availableTime => {
      return availableTime.nonTzValue === time?.nonTzValue;
    });

    if (newTime && isAvailableTimes) {
      setTime(newTime);
    }
  }, [displayTimezone.value, isAvailableTimes]);

  useEffect(() => {
    if (isAvailableTimes && availableTimesOptions) {
      // if "only show available times" is selected and there are available options
      // then select the first option, if not then prevent from rescheduling.
      const hasAvailableTimes = availableTimesOptions.length > 0;
      setTime(hasAvailableTimes ? availableTimesOptions[0] : null);
    } else {
      setTime(APPOINTMENT_TIME_10_AM);
    }
  }, [availableTimesOptions?.length, isAvailableTimes]);

  useEffect(() => {
    if (date && isGuessTimezoneReady) {
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).tz(displayTimezone.value);
      date.add(time?.value, 'hours');
    }
  }, [time, date, isGuessTimezoneReady, day.value]);

  useEffect(() => {
    if (isAvailableTimesLoading || !date) {
      setConflicts([]);
      return;
    }

    const baseDate = dayjs().tz(displayTimezone.value).startOf('week').add(Number(day.value), 'day');

    const selectedDateFormatted = baseDate
      .add(Number(day.value) === 0 ? 7 : 0, 'day')
      .add(time?.value || 0, 'hours')
      .utc()
      .toISOString();

    const isDateInAvailableTimes = (item: any) => {
      const itemTime = moment(item.time).tz(displayTimezone.value).toISOString();
      return itemTime === selectedDateFormatted;
    };

    const generateRecurringDates = () => {
      return Array.from({ length: 10 }, (_, i) => {
        const dateMoment = moment(selectedDateFormatted).add(i * 7, 'days');
        return dateMoment.toISOString();
      });
    };

    const checkFutureConflicts = (conflicts: IConflict[]) => {
      if (!availableTimesV2?.future) return;

      const recurringDates = generateRecurringDates();
      const recurringConflicts = new Map<string, IConflict>();

      conflicts.forEach(conflict => {
        recurringConflicts.set(conflict?.datetime, conflict);
      });

      availableTimesV2.future.forEach((report: any, reportIndex: number) => {
        const recurringDate = recurringDates[reportIndex];
        const dateMoment = moment(recurringDate);
        const isInWeek = dateMoment.isBetween(moment(report?.start), moment(report?.end), null, '[)');

        if (isInWeek) {
          const isAvailable = report.availability?.some((availableDate: string) => {
            const dateToCompare = moment(availableDate);
            return dateToCompare.toISOString() === recurringDate;
          });

          const isConflict = report.conflicts || (!isAvailableTimes && !isAvailable);

          if (isConflict && !recurringConflicts.has(recurringDate)) {
            recurringConflicts.set(recurringDate, {
              datetime: recurringDate,
              type: 'futureConflict',
            });
          }
        }
      });

      const sortedConflicts = Array.from(recurringConflicts.values()).sort(
        (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime(),
      );

      setConflicts(sortedConflicts);
    };

    const handleOverride = () => {
      const isAvailable = availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = selectedDateFormatted;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: selectedDateFormatted,
          type: isAvailableTimes ? 'appointmentExists' : 'futureConflict',
        });
      }

      setConflicts(Array.from(conflictMap.values()));

      checkFutureConflicts(Array.from(conflictMap.values()));
    };

    const handleNonOverride = () => {
      const isAvailable =
        availableTimesV2?.items?.length === 0 || availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = selectedDateFormatted;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: selectedDateFormatted,
          type: isAvailableTimes ? 'appointmentExists' : 'futureConflict',
        });
      }

      const selectedItem = availableTimesV2?.items?.find(isDateInAvailableTimes);
      selectedItem?.conflicts?.forEach((conflict: { datetime: string; type: string }) => {
        conflictMap.set(conflict?.datetime, {
          datetime: conflict?.datetime,
          type: isAvailableTimes ? conflict?.type : 'futureConflict',
        });
      });

      setConflicts(Array.from(conflictMap.values()));

      checkFutureConflicts(Array.from(conflictMap.values()));
    };

    if (!isAvailableTimes) handleOverride();
    else handleNonOverride();
  }, [isAvailableTimesLoading, isAvailableTimes, availableTimesV2, day, date, time, displayTimezone, setConflicts]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (actionType === RecurringAppointmentsActionType.Reassign) {
      await reassignRecurringAppointment(
        {
          clientID: editRecurringAppointmentData.clientID,
          therapistID: selectedTherapist.value,
          recurringAppointmentID: editRecurringAppointmentData.recurringAppointmentID,
        },
        {
          onError: err => {
            const error = getCueError(err);
            const errorMessage =
              error.keyword === RecurringAppointmentsKeywordErrors.NotFound ? error.keyword : error.message;
            setErrorMessage(errorMessage);
            return;
          },
        },
      );
    }

    if (actionType === RecurringAppointmentsActionType.Reschedule) {
      const dayOfWeek = getDateFromDayOfWeekAndTime(
        Number(day.value),
        date?.format('HH:mm') as string,
        displayTimezone.value,
      ).dayOfWeek;
      const time = getDateFromDayOfWeekAndTime(
        Number(day.value),
        date?.format('HH:mm') as string,
        displayTimezone.value,
      ).time;

      await rescheduleRecurringAppointment(
        {
          clientID: editRecurringAppointmentData.clientID,
          recurringAppointmentID: editRecurringAppointmentData.recurringAppointmentID,
          dayOfWeek: dayOfWeek,
          time: time,
          rescheduleDetail: {
            rescheduledBy,
            rescheduledReason,
            rescheduledReasonOther,
          },
          override: true,
        },
        {
          onError: err => {
            const error = getCueError(err);
            const errorMessage =
              error.keyword === RecurringAppointmentsKeywordErrors.NotFound ? error.keyword : error.message;
            setErrorMessage(errorMessage);
            return;
          },
        },
      );
    }

    queryClient.invalidateQueries();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalContent>
          <form onSubmit={onSubmit}>
            <ModalHeader>
              <div className="flex flex-col">
                <div tw="absolute top-0 right-0 pt-4 pr-4">
                  <Link data-testid="edit-recurring-appointment-close" to="#" onClick={onClose}>
                    <FontAwesomeIcon tw="text-2xl text-gray-600" icon={['far', 'times']} />
                  </Link>
                </div>
                <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                  <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="calendar-check" />
                </div>
                <div tw="mt-3 sm:mt-5">
                  <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                    Edit Recurring Session
                  </h3>
                  <div tw="mt-2">
                    <h4 tw="text-center leading-6">
                      {dayjs(editRecurringAppointmentData?.startDate).tz(dayjs.tz.guess()).format('dddd')}
                      {'s'} at&nbsp;
                      {dayjs(editRecurringAppointmentData?.startDate).tz(dayjs.tz.guess()).format('hh:mm A')}
                    </h4>
                    <h4 tw="text-center">
                      with {editRecurringAppointmentData.therapistFirstName}{' '}
                      {editRecurringAppointmentData.therapistLastName}{' '}
                    </h4>
                  </div>

                  {isCancelAllowed && (
                    <Link
                      data-testid="edit-recurring-appointment-cancel"
                      to="#"
                      tw="block text-red-500 text-center mt-4"
                      className={`${isLoadingState ? 'cursor-not-allowed opacity-65' : ''}`}
                      onClick={() => {
                        if (!isLoadingState) {
                          onClose();
                          openCancelModal();
                        }
                      }}
                    >
                      <FontAwesomeIcon tw="text-base mr-2" icon={['far', 'trash']} />
                      Cancel Recurring Session
                    </Link>
                  )}
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              {upcomingRecurringAppointments &&
                upcomingRecurringAppointments.length > 0 &&
                !isAvailableTimesLoadingError &&
                !errorMessage && (
                  <div>
                    <div>
                      <div>
                        <div tw="mt-5">
                          <FormGroupTitle
                            title="What do you want to do?"
                            fontSize="small"
                            fontWeight="semi"
                            spacing="large"
                          />
                          <FormGroup type="inline" tw="items-center">
                            <Input
                              type="radio"
                              id="actiontype-reschedule"
                              spacing="tight"
                              data-testid="edit-reschedule-recurring-appointment"
                              value="reschedule"
                              checked={actionType === 'reschedule'}
                              disabled={isLoadingState}
                              onChange={handleRadioButtonActionType}
                            />
                            <Label font="normal" htmlFor="actiontype-reschedule" tw="ml-2">
                              Reschedule
                            </Label>

                            <Input
                              id="actiontype-reassign"
                              tw="ml-4"
                              spacing="tight"
                              type="radio"
                              data-testid="edit-reassign-recurring-appointment"
                              value="reassign"
                              checked={actionType === 'reassign'}
                              disabled={isLoadingState}
                              onChange={handleRadioButtonActionType}
                            />
                            <Label font="normal" htmlFor="actiontype-reassign" tw="ml-2">
                              Reassign
                            </Label>
                          </FormGroup>
                        </div>
                        {actionType === RecurringAppointmentsActionType.Reschedule && (
                          <div tw="mt-4">
                            <div tw="text-sm  text-gray-500 leading-5">
                              Times are shown in -
                              <Menu
                                onChange={menuItem => {
                                  const selectedMenuItem = menuItem as Record<string, string>;
                                  setDisplayTimezone(selectedMenuItem);
                                  if (date && selectedMenuItem) {
                                    setDate(date.tz(selectedMenuItem.value, true));
                                  }
                                }}
                                tw="px-1 inline"
                              >
                                <MenuLink to="#" data-testid="edit-recurring-appointment-time-zone">
                                  {displayTimezone?.label} Time Zone
                                  <FontAwesomeIcon tw="text-sm ml-1" icon="caret-down" />
                                </MenuLink>
                                <MenuList tw="w-full">
                                  {displayTimezoneOptions.map((option, index) => (
                                    <MenuItem value={option} key={index}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </Menu>
                            </div>
                          </div>
                        )}
                        <div tw="mt-4">
                          {actionType === RecurringAppointmentsActionType.Reschedule && (
                            <FormGroup type="inline" tw="items-center">
                              <div tw="flex justify-center text-center">
                                <Input
                                  data-testid="edit-recurring-appointment-available-times"
                                  name="available-times"
                                  id="available-times"
                                  type="checkbox"
                                  tw="mr-2"
                                  checked={isAvailableTimes}
                                  disabled={isLoadingState}
                                  onChange={e => setIsAvailableTimes(e.currentTarget.checked)}
                                />
                                <Label font="normal" htmlFor="available-times">
                                  Only Show Available Times
                                </Label>
                              </div>
                            </FormGroup>
                          )}
                        </div>
                        <FormGroup data-testid="edit-recurring-appointment-values">
                          {actionType === RecurringAppointmentsActionType.Reassign && (
                            <Select
                              required
                              aria-label="edit-recurring-appointment-therapist"
                              name="edit-recurring-appointment-therapist"
                              tw="mt-2 mb-4"
                              data-testid="edit-recurring-appointment-therapist"
                              options={uniqBy(
                                [
                                  ...(therapistSelectOptions ?? []),
                                  {
                                    label: `${therapist?.firstName} ${therapist?.lastName}`,
                                    value: therapist?.therapistID,
                                  },
                                ],
                                'value',
                              )}
                              disabled={isLoadingState}
                              onChange={(therapist: SelectOption) => {
                                setSelectedTherapist(therapist);
                              }}
                              defaultValue={selectedTherapist}
                            />
                          )}
                          {actionType === RecurringAppointmentsActionType.Reschedule && (
                            <>
                              <div data-testid="add-appointment-day">
                                <Select
                                  required
                                  aria-label="edit-recurring-appointment-day"
                                  name="edit-recurring-appointment-day"
                                  tw="mt-0 mb-4"
                                  value={day}
                                  options={DAY_SELECT_OPTIONS}
                                  disabled={isLoadingState}
                                  onChange={(day: SelectOption) => {
                                    setDay(day);
                                  }}
                                />
                              </div>
                              <div data-testid="add-appointment-time">
                                {isAvailableTimesLoading ? (
                                  <div
                                    data-testid="add-appointment-loading"
                                    tw="flex-1 mt-3 mb-3 text-center text-gray-500"
                                  >
                                    Fetching the therapist&apos;s availability...
                                  </div>
                                ) : (
                                  <Select
                                    aria-label="time-dropdown"
                                    name="time-dropdown"
                                    spacing="small"
                                    value={time}
                                    options={isAvailableTimes ? availableTimesOptions : timeOptions}
                                    disabled={isLoadingState}
                                    onChange={(timeOption: { label: string; value: number; nonTzValue: number }) => {
                                      setTime(timeOption);
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </FormGroup>
                        {actionType === RecurringAppointmentsActionType.Reschedule && (
                          <div tw="mt-2">
                            <FormGroupTitle
                              title="Reschedule initiated by"
                              fontSize="small"
                              fontWeight="semi"
                              spacing="large"
                            />
                            <FormGroup type="inline" tw="items-center">
                              <Input
                                type="radio"
                                id="rescheduledBy-client"
                                name="rescheduledBy"
                                spacing="tight"
                                data-testid="reschedule-by-client"
                                value={RescheduledBy.Client}
                                required
                                disabled={isLoadingState}
                                onChange={handleOnChangeRescheduledBy}
                                checked={rescheduledBy === RescheduledBy.Client}
                              />
                              <Label font="normal" htmlFor="rescheduledBy-client" tw="ml-2">
                                Client
                              </Label>
                              <Input
                                id="rescheduledBy-therapist"
                                name="rescheduledBy"
                                tw="ml-4"
                                spacing="tight"
                                type="radio"
                                data-testid="reschedule-by-therapist"
                                value={RescheduledBy.Therapist}
                                required
                                disabled={isLoadingState}
                                onChange={handleOnChangeRescheduledBy}
                                checked={rescheduledBy === RescheduledBy.Therapist}
                              />
                              <Label font="normal" htmlFor="rescheduledBy-therapist" tw="ml-2">
                                Therapist
                              </Label>
                            </FormGroup>
                            <FormGroup tw="mt-5" data-testid="reschedule-reason">
                              <FormGroupTitle
                                title="Reschedule Reason"
                                fontSize="small"
                                fontWeight="semi"
                                spacing="large"
                              />
                              <Select
                                required
                                tw="mb-1"
                                aria-label="reschedule-reason"
                                name="reschedule-reason"
                                data-testid="reschedule-reason"
                                options={cancelationReasons}
                                disabled={isLoadingState}
                                onChange={(selectedOption: SelectOption) => {
                                  setRescheduledReason(selectedOption.value);
                                }}
                                value={cancelationReasons?.find(reason => reason.value === rescheduledReason)}
                              />
                            </FormGroup>
                            {rescheduledReason === 'other' && (
                              <FormGroup>
                                <FormGroupTitle
                                  title="What was the reason?"
                                  fontSize="small"
                                  fontWeight="semi"
                                  spacing="normal"
                                />
                                <Input
                                  type="text"
                                  tw="w-full"
                                  data-testid="reschedule-reason-other"
                                  required
                                  disabled={isLoadingState}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setRescheduledReasonOther(event.target.value);
                                  }}
                                  value={rescheduledReasonOther}
                                />
                              </FormGroup>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {!isLoadingState &&
                      actionType === RecurringAppointmentsActionType.Reschedule &&
                      conflicts.length > 0 && (
                        <>
                          <div className="mt-4 mb-5">
                            <Conflicts
                              conflicts={conflicts}
                              availableTimes={false}
                              displayTimezone={displayTimezone.value}
                            />
                          </div>
                        </>
                      )}
                  </div>
                )}
            </ModalBody>
            <ModalFooter>
              {errorMessage === RecurringAppointmentsKeywordErrors.NotFound ? (
                <p tw="text-center text-red-600 font-semibold mb-4 mt-4">
                  This recurring appointment can no longer be found.
                </p>
              ) : (
                <p tw="text-center text-red-600 font-semibold mb-4 mt-4">{errorMessage}</p>
              )}
              {!upcomingRecurringAppointments?.length && !upcomingRecurringAppointmentsLoading && (
                <p tw="text-center text-gray-500 mb-4 mt-4">
                  There are no appointments associated with this recurring appointment. Please cancel this recurring
                  appointment instead.
                </p>
              )}
              {isAvailableTimesLoadingError && (
                <p tw="text-center text-gray-500 mb-4 mt-4">
                  {`Sorry, we couldn't load ${editRecurringAppointmentData?.therapistFirstName} ${editRecurringAppointmentData?.therapistLastName}'s calendar. Please try again shortly, or reach out to our help center for assistance.`}
                </p>
              )}
              <div tw="">
                <span tw="flex w-full rounded-md shadow-sm sm:col-start-2">
                  <Button
                    loading={isLoadingState}
                    className={`${
                      isLoadingState ||
                      (actionType === RecurringAppointmentsActionType.Reschedule &&
                        (!time ||
                          !day ||
                          (isAvailableTimes && !availableTimesOptions?.length) ||
                          !rescheduledBy ||
                          !rescheduledReason))
                        ? 'cursor-not-allowed bg-gray-300'
                        : ''
                    }`}
                    disabled={
                      !upcomingRecurringAppointments?.length ||
                      (actionType === RecurringAppointmentsActionType.Reschedule &&
                        (!time ||
                          !day ||
                          (isAvailableTimes && !availableTimesOptions?.length) ||
                          !rescheduledBy ||
                          !rescheduledReason))
                    }
                    data-testid="edit-recurring-appointment-submit"
                    type="submit"
                    variant="primary"
                    tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    {actionType === RecurringAppointmentsActionType.Reassign
                      ? `Reassign ${
                          upcomingRecurringAppointmentsLoading || !upcomingRecurringAppointments?.length
                            ? ''
                            : upcomingRecurringAppointments.length === 1
                            ? '1 Appointment'
                            : `${upcomingRecurringAppointments.length} Appointments`
                        }`
                      : `Reschedule ${
                          upcomingRecurringAppointmentsLoading || !upcomingRecurringAppointments?.length
                            ? ''
                            : upcomingRecurringAppointments.length === 1
                            ? '1 Appointment'
                            : `${upcomingRecurringAppointments.length} Appointments`
                        }`}
                  </Button>
                </span>
              </div>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
